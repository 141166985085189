import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
let proxy = "https://cors-anywhere.herokuapp.com/";

@Injectable({
  providedIn: 'root'
})

export class GlobalService {
  public apiHostUrl;
  apiHost: string;
  constructor() {
    if (environment.production == true) {
        //this.apiHostUrl = 'http://localhost/budspace/api/web/v1';
      this.apiHostUrl = 'http://api1.staging10.bigcityvoucher.co.in/v1';
    } else {
      //this.apiHostUrl = 'http://localhost/budspace/api/web/v1';
      this.apiHostUrl = 'http://api1.staging10.bigcityvoucher.co.in/v1';
    }
  }

}

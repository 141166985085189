import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './modules/layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { CacheInterceptor } from './modules/Service/Http/cache.interceptor';
import { RouteDataProvider } from './modules/Shared/RouteDataProvider.provider';
import { AuthGuard } from './modules/Shared/AuthGuard';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ProgramDataService } from './modules/Service/program-data.service';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxUiLoaderModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true
    }),

  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    //{ provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    RouteDataProvider, AuthGuard, ProgramDataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
